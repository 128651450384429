import React, {useEffect, useRef, useState} from 'react';

import cl from "../scss/audio.module.scss"
import useSound from "use-sound";
const Audio = () => {
    const refCursor = useRef()
    const refTrack = useRef()
    const [musicState, setMusicState] = useState(false)
    const [play, {pause, duration, sound}] = useSound("music/sound.mp3", {
        onend: () => {
            setMusicState(false)
            refTrack.current.querySelectorAll("*").forEach(item=>{
                item.style.background = "#555555"
                item.style.height = "4px"
            })
        }})

    const [seconds, setSeconds] = useState();
    useEffect(() => {
        const interval = setInterval(() => {
            if (sound) {
                setSeconds(sound.seek([]));
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [sound]);

    useEffect(()=>{
        if(!seconds*100/(duration/1000)){
            refCursor.current.style.setProperty("--prosentDistans", 0);
        }else{
            refCursor.current.style.setProperty("--prosentDistans", seconds*100/(duration/1000));
        }
        let items = refTrack.current.querySelectorAll("*")
        let oneSection = duration/1000/items.length;
        let i = Math.floor(seconds/oneSection) | 0;
        let height = Math.random() * 30 + 20
        if(seconds>0.5) {
            items[i].style.background = "#fff"
            items[i].style.height = height + "px";
        }
    }, [seconds, duration])
    const soundHandler =()=>{
        if(musicState){
            pause();
            setMusicState(false)
        }else{
            play();
            setMusicState(true)
        }
    }

    let span = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,23, 24]
    return (
        <div  className={cl.block}>
            <div className={cl.body}>
                <div className={cl.circle}></div>
                <div ref={refCursor} className={cl.cursor}>
                    <span></span>
                    <span></span>
                </div>
                <div className={cl.track} ref={refTrack}>
                    {span.map((index, item)=>
                        <span key={index} className={cl.Active}></span>
                    )}
                </div>
            </div>
            <button className={cl.btn} onClick={soundHandler}>
                {!musicState
                ?<svg xmlns="http://www.w3.org/2000/svg" width="29" height="34" viewBox="0 0 29 34" fill="none">
                        <path d="M27 13.5359C29.6667 15.0755 29.6667 18.9245 27 20.4641L6 32.5885C3.33334 34.1281 2.18848e-06 32.2036 2.32307e-06 29.1244L3.38302e-06 4.87565C3.51762e-06 1.79644 3.33334 -0.128059 6 1.41154L27 13.5359Z" fill="white"/>
                    </svg>
                :<svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 20 35" fill="none">
                        <rect width="6.92308" height="35" rx="3.46154" fill="white"/>
                        <rect x="13.0769" width="6.92308" height="35" rx="3.46154" fill="white"/>
                    </svg>
                }
            </button>
        </div>
    );
};

export default Audio;