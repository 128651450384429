import Main from "./page/Main";
import Confidentiality from "./page/Confidentiality";
export const routes = [
    {
        path: '/',
        Component: Main
    },
    {
        path: '/confidentiality',
        Component: Confidentiality
    },

]