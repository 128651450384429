import React  from 'react';
import './scss/index.scss';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";

const App = () => {
    return (
        <div className="App">
            <div className="wrapper">
                <BrowserRouter>
                    <AppRouter/>
                </BrowserRouter>
            </div>
        </div>
    )
};

export default App;