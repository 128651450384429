import React from 'react';
import cl from "../../scss/price.module.scss";

const Price = () => {
    return (
        <div>
            <div className={cl.block}>
                <div className={cl.container}>
                    <h2 className={cl.title}>ЦЕНЫ</h2>
                    <div className={cl.row}>
                        <div className={cl.item}>
                            <picture className={cl.itemImage}>
                                <source srcSet="/images/price/lending.png"/>
                                <img src="/images/price/lending.png" alt="Лендинг"/>
                            </picture>
                            <div className={cl.itemTitle}>Лендинг</div>
                            <div className={cl.itemText}>Продающая посадочная страница для продвижения конкретного
                                товара или услуги
                            </div>
                            <div className={cl.btn}>от 25 000р</div>
                        </div>
                        <div className={cl.item}>
                            <picture className={cl.itemImage}>
                                <source srcSet="/images/price/corporate.png"/>
                                <img src="/images/price/corporate.png" alt="Корпоративный"/>
                            </picture>
                            <div className={cl.itemTitle}>Корпоративный</div>
                            <div className={cl.itemText}>Сайт визитка с большим количеством информации о компании
                            </div>
                            <div className={cl.btn}>от 50 000р</div>
                        </div>
                        <div className={cl.item}>
                            <picture className={cl.itemImage}>
                                <source srcSet="/images/price/e-commerce.png"/>
                                <img src="/images/price/e-commerce.png" alt="Интернет магазин"/>
                            </picture>
                            <div className={cl.itemTitle}>Интернет магазин</div>
                            <div className={cl.itemText}>Работа с товарами или услугами в удобной CMS системе. Прием заказов через интеграции</div>
                            <div className={cl.btn}>от 80 000р</div>
                        </div>
                        <div className={cl.item}>
                            <picture className={cl.itemImage}>
                                <source srcSet="/images/price/service.png"/>
                                <img src="/images/price/service.png" alt="Веб сервис"/>
                            </picture>
                            <div className={cl.itemTitle}>Веб сервис</div>
                            <div className={cl.itemText}>Разработка веб сервиса на освное api или создание своих систем и приложений</div>
                            <div className={cl.btn}>от 150 000р</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Price;