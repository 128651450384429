import React from 'react';
import cl from "../scss/footer.module.scss"
const Footer = () => {
    return (
        <div className={cl.block}>
            <div className={cl.container}>
                <div>© 2023</div>
                <div>WEBIRAI Студия брендинга и веб разработки. Все права защищены</div>
            </div>
        </div>
    );
};

export default Footer;