import React, {useState} from 'react';
import Header from "../components/header";
import Order from "../components/modals/order";
import About from "../components/blocks/About";
import Do from "../components/blocks/do";
import Feedback from "../components/blocks/feedback";
import Footer from "../components/Footer";
import MainBlock from "../components/blocks/MainBlock";
import Price from "../components/blocks/Price";

const Main = () => {
    const [popup, setPopup] = useState(false);
    return (
        <div>
            <Header/>
            <div className="page">
                <Order visible={popup} setVisible={setPopup}/>
                <MainBlock setPopup={setPopup}/>
                <About/>
                <Do/>
                <Price/>
                <Feedback/>
            </div>
            <Footer/>
        </div>
    )
};

export default Main;