import React, {useEffect, useState} from 'react';

const Service = ({children, classProperty, list, setList}) => {
    const [status, setStatus] = useState(false)
    const [classBtn, setClassBtn] = useState([classProperty.item])
    const [service, setService] = useState([])

    useEffect(() => {
        setService(list.services);
    }, [list]);

    const buttonHandler = ()=>{
        let item = String(children)
        if(status){
            setStatus(false)
            setClassBtn([classProperty.item])
            const index = service.indexOf(item);
            if (index > -1) {
                let arr = service;
                arr.splice(index, 1);
                setList({...list, services: arr})
            }
        }else{
            setStatus(true)
            setClassBtn([classProperty.item, classProperty.itemActive])
            setList({...list, services: [...service, item]})

        }
    }


    return (
        <button className={classBtn.join(" ")} onClick={buttonHandler}>
            {children}
        </button>
    );
};

export default Service;