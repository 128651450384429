import React from 'react';
import cl from "../../scss/do.module.scss"

const Do = () => {
    return (
        <section className={cl.block}>
            <div className={cl.container}>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h2 className={cl.title}>ТО, ЧТО МЫ ДЕЛАЕМ</h2>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.gif}></div>
                    </div>
                </div>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h3 className={cl.subTitle}>UX  UI Дизайн</h3>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.text}>
                            Не просто эстетика. Мы создаем интуитивно понятные, визуально привлекательные и, прежде всего, удобные интерфейсы. Понимая потребности пользователей, движение приведет к удовлетворению, вовлечению и конверсии.
                        </div>
                    </div>
                </div>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h3 className={cl.subTitle}>Веб разработка</h3>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.text}>
                            Ваше цифровое присутствие - надежно и эффективно.
                            Разработка промо страниц на tilda, интернет магазины с
                            <span> нашей cms</span> или на любой  платформе, веб приложения, корпоративные сайты и всё что нужно для бизнеса
                        </div>
                    </div>
                </div>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h3 className={cl.subTitle}>Брендинг</h3>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.text}>
                            Создадим сильный и узнаваемый фирменный стиль, который будет выделяться на рынке. Наша команда вместе с вами разработает комплексную стратегию бренда, которая будет соответствовать целям и задачам вашего бизнеса.
                        </div>
                    </div>
                </div>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h3 className={cl.subTitle}>SMM</h3>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.text}>
                            Подключение, привлечение и конверсия. Мы создаем, курируем и управляем контентом. Реклама продукта на целевую аудитори. Наблюдайте за тем, как ваши
                            подписчики превращаются в лояльных клиентов.
                        </div>
                    </div>
                </div>
                <div className={cl.row}>
                    <div className={cl.column}>
                        <h3 className={cl.subTitle}>Видео</h3>
                    </div>
                    <div className={cl.column}>
                        <div className={cl.text}>
                            Видео контент - is king. Пост продакшен, моушен дизайн, съемки контента. Придаем визуальному контенту дополнительную изюминку. Позаботимся о том, чтобы ваше сообщение не просто увидели, но и запомнили.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Do;