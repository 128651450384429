import React, {useEffect, useState} from 'react';
import cl from "../scss/header.module.scss"
import {useScrollLock} from "../hooks";
const Header = () => {

    const [burger, setBurger] = useState(false);
    const [burgerClass, setBurgerClass] = useState([cl.burger]);
    const [burgerClassBtn, setBurgerClassBtn] = useState([cl.btnBurger]);
    const [scrollTop, setScrollTop] = useState(0)
    const scroll = useScrollLock()
    const burgerHandler = () =>{
        if(burger){
            setBurger(false)
            return
        }
        setBurger(true)
    }
    const handleScroll =() =>{
        setScrollTop(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(()=>{
        if(burger){
            setBurgerClass([cl.burger, cl.burgerActive]);
            setBurgerClassBtn([cl.btnBurger, cl.btnBurgerCross]);
            scroll.lockScroll()
        }else{
            setBurgerClass([cl.burger]);
            setBurgerClassBtn([cl.btnBurger]);
            scroll.unlockScroll()
        }
    }, [burger, scroll])

    const up = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <header className={cl.header}>
            <div className={cl.container}>
                {burger && <div className={cl.wrapper} onClick={burgerHandler}></div>}
                <div className={cl.body}>
                    <div>
                        <a href="/" className={cl.logo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="169" height="35" viewBox="0 0 169 35" fill="none">
                                <path d="M45.4822 9.24H38.4072L31.5519 22.3067L22.7631 9.24L13.9742 22.3067L7.11896 9.24H0L13.1393 34.2067L22.7631 19.8333L32.3429 34.2067L45.4822 9.24Z"/>
                                <path d="M58.3026 34.6733C62.6091 34.6733 66.4323 33.5533 68.8052 30.7067L64.1472 26.9267C63.3562 28.0933 60.5437 28.8867 58.9178 28.84C55.9296 28.84 53.4248 26.6933 53.1172 24.4533H68.8052C71.0024 15.12 65.5534 8.58667 57.7313 8.54C50.5684 8.49333 46.3937 14.84 46.3937 21.56C46.3937 28 51.1836 34.6733 58.3026 34.6733ZM53.0732 18.8067C53.0732 16.8 54.6992 14.28 57.951 14.28C61.7302 14.28 63.0046 17.1267 62.7849 18.8067H53.0732Z"/>
                                <path d="M86.1604 9.05333C83.6996 9.05333 81.4584 10.2667 80.0961 12.04V1.54H73.944V22.0267C73.944 29.2133 79.437 35 86.1604 35C92.8839 35 98.3769 29.2133 98.3769 22.0267C98.3769 14.8867 92.8839 9.05333 86.1604 9.05333ZM86.1604 28.4667C82.8207 28.4667 80.0961 25.62 80.0961 22.0267C80.0961 18.48 82.8207 15.5867 86.1604 15.5867C89.5002 15.5867 92.2247 18.48 92.2247 22.0267C92.2247 25.62 89.5002 28.4667 86.1604 28.4667Z"/>
                                <path d="M106.363 6.95333C108.165 6.95333 109.615 5.27333 109.615 3.45333C109.615 1.58667 108.165 0 106.363 0C104.606 0 103.112 1.58667 103.112 3.45333C103.112 5.27333 104.606 6.95333 106.363 6.95333ZM103.419 34.2067H109.264V9.24H103.419V34.2067Z"/>
                                <path d="M115.99 34.2067H122.142V21.42C122.142 17.5 125.57 15.12 129.13 15.7733V9.24C126.317 9.1 122.933 9.89333 121.835 12.1333V9.24H115.99V34.2067Z"/>
                                <path d="M144.76 34.4867C147.221 34.4867 149.374 33.32 150.737 31.5933V34.6733H156.889V21.56C156.889 14.4667 151.44 8.68 144.76 8.68C138.037 8.68 132.588 14.4667 132.588 21.56C132.588 28.7 138.037 34.4867 144.76 34.4867ZM144.76 27.9533C141.421 27.9533 138.74 25.1067 138.74 21.56C138.74 18.06 141.421 15.2133 144.76 15.2133C148.056 15.2133 150.737 18.06 150.737 21.56C150.737 25.1067 148.056 27.9533 144.76 27.9533Z"/>
                                <path d="M165.748 6.95333C167.55 6.95333 169 5.27333 169 3.45333C169 1.58667 167.55 0 165.748 0C163.99 0 162.496 1.58667 162.496 3.45333C162.496 5.27333 163.99 6.95333 165.748 6.95333ZM162.804 34.2067H168.648V9.24H162.804V34.2067Z"/>
                            </svg>
                            <span>ТВОЯ КОМАНДА</span>
                        </a>
                    </div>
                    <div>
                        <ul className={cl.menu}>
                            <li className={cl.menuItem}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <a className={cl.link} target="_blank" href="https://wa.me/79649141293" rel="noreferrer">WHATSAPP</a>
                            </li>
                            <li className={cl.menuItem}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                    <path d="M1 1.5L7.5 8L1 14.5" strokeWidth="2"/>
                                </svg>
                                <a className={cl.link} target="_blank" href="mailto:webirai.studio@gmail.com" rel="noreferrer">EMAIL</a>
                            </li>
                            <li className={cl.menuItem}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <a className={cl.link} target="_blank" href="https://vk.com/webirai" rel="noreferrer">VKONTAKTE</a>
                            </li>
                        </ul>
                    </div>
                    {/*<div>*/}
                    {/*    <ul className={cl.menu}>*/}
                    {/*        <li className={cl.menuItem}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">*/}
                    {/*                <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*            </svg>*/}
                    {/*            <a className={cl.link} href="/">WHATSAPP</a>*/}
                    {/*        </li>*/}
                    {/*        <li className={cl.menuItem}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">*/}
                    {/*                <path d="M1 1.5L7.5 8L1 14.5" strokeWidth="2"/>*/}
                    {/*            </svg>*/}
                    {/*            <a className={cl.link} href="/">EMAIL</a>*/}
                    {/*        </li>*/}
                    {/*        <li className={cl.menuItem}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">*/}
                    {/*                <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*            </svg>*/}
                    {/*            <a className={cl.link} href="/">VKONTAKTE</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
                <div className={burgerClass.join(" ")} onClick={e=>{e.stopPropagation()}}>
                    <ul className={[cl.menu, cl.menuBurger].join(" ")}>
                        <li className={cl.menuItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <a className={cl.link} target="_blank" href="https://wa.me/79649141293" rel="noreferrer">WHATSAPP</a>
                        </li>
                        <li className={cl.menuItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path d="M1 1.5L7.5 8L1 14.5" strokeWidth="2"/>
                            </svg>
                           <a className={cl.link} target="_blank" href="mailto:webirai.studio@gmail.com" rel="noreferrer">EMAIL</a>
                        </li>
                        <li className={cl.menuItem}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <a className={cl.link} target="_blank" href="https://vk.com/webirai" rel="noreferrer">VKONTAKTE</a>
                        </li>
                    </ul>
                    {/*<ul className={[cl.menu, cl.menuBurger].join(" ")}>*/}
                    {/*    <li className={cl.menuItem}>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">*/}
                    {/*            <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*        </svg>*/}
                    {/*        <a className={cl.link} href="/">WHATSAPP</a>*/}
                    {/*    </li>*/}
                    {/*    <li className={cl.menuItem}>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">*/}
                    {/*            <path d="M1 1.5L7.5 8L1 14.5" strokeWidth="2"/>*/}
                    {/*        </svg>*/}
                    {/*        <a className={cl.link} href="/">EMAIL</a>*/}
                    {/*    </li>*/}
                    {/*    <li className={cl.menuItem}>*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">*/}
                    {/*            <path d="M1 10L10 0.999999M10 0.999999L1.36 1M10 0.999999L10 9.64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*        </svg>*/}
                    {/*        <a className={cl.link} href="/">VKONTAKTE</a>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                </div>
                <button className={burgerClassBtn.join(" ")} onClick={burgerHandler}>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className={
                scrollTop < 150
                ? [cl.up].join(" ")
                : [cl.up, cl.upActive].join(" ")
            }
                 onClick={up}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="17" viewBox="0 0 31 17" fill="none">
                    <path d="M31 0H26.1778L21.5053 8.8972L15.515 0L9.52464 8.8972L4.85217 0H0L8.95555 17L15.515 7.21308L22.0444 17L31 0Z" fill="#F0F0F0" fillOpacity="0.9"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path d="M7.36401 13.7275V0.999617M7.36401 0.999617L1.25461 7.10902M7.36401 0.999617L13.4734 7.10902" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </header>
    );
};

export default Header;