import React from 'react';
import {isMobile} from "react-device-detect"
export const useScrollLock = () => {
    const lockScroll = React.useCallback(() => {
        document.body.style.overflow = 'hidden';
        if(!isMobile){
            document.body.style.paddingRight = "10px"
        }

    }, [])

    const unlockScroll = React.useCallback(() => {
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = "0px"
    }, [])

  return {
 lockScroll,
 unlockScroll
 };
}
