import React, {useEffect, useRef} from 'react';
import cl from "../../scss/main.module.scss"
const MainBlock = ({setPopup}) => {
    const refBtn = useRef()
    const refObject = useRef()

    const orderHandler = (e)=>{
        e.preventDefault()
        setPopup(true)
    }

    useEffect(()=> {
        refObject.current.style.setProperty('--x', 90 + 'px')
        refObject.current.style.setProperty('--y', 90 + 'px')
    }, [])
    const handler =(e)=>{
        const x = e.pageX - refBtn.current.offsetLeft;
        const y = e.pageY - refBtn.current.offsetTop;
        refObject.current.style.setProperty('--x', x + 'px')
        refObject.current.style.setProperty('--y', y + 'px')
        if((refBtn.current.offsetTop>e.pageY || refBtn.current.offsetLeft>e.pageX) || (refBtn.current.offsetLeft + refBtn.current.clientWidth < e.pageX || refBtn.current.offsetTop + refBtn.current.clientHeight < e.pageY)){
            refObject.current.style.setProperty('--x', 90 + 'px')
            refObject.current.style.setProperty('--y', 90 + 'px')
            refObject.current.style.transition =  "all 0.5s ease 0s";
        }else{
            refObject.current.style.transition =  "all 0s ease 0s";
        }
    }
    const leave =(e)=> {
        refObject.current.style.setProperty('--x', 90 + 'px')
        refObject.current.style.setProperty('--y', 90 + 'px')
        refObject.current.style.transition =  "all 0.5s ease 0s";
    }


    return (
        <section className={cl.main}>
            <div className={cl.container}>
                <div className={cl.column}>
                    <div className={cl.collage}>
                        <img src="/images/avatars/igor.jpg" alt=""/>
                        <img src="/images/avatars/egor.jpg" alt=""/>
                        <img src="/images/avatars/liza.jpg" alt=""/>
                        <img src="/images/avatars/artur.jpg" alt=""/>
                    </div>
                </div>
                <div className={cl.column}>
                    <div className={cl.col}>
                        <h1 className={cl.title}>PROFESSIONAL УСЛУГИ <span>ВЕБ</span> РАЗРАБОТКИ
                            И БРЕНДИНГА</h1>
                    </div>
                    <div className={cl.col}>
                        <div className={cl.row}>
                            <div className={cl.gifSphere}></div>
                            <a ref={refBtn} href="/" className={cl.orderBtn} onClick={e=>orderHandler(e)} onMouseMove={e=>handler(e)} onMouseLeave={leave} >
                            <span>
                                Оставить<br/>
                                заявку
                        </span>
                                <span ref={refObject}></span>
                            </a>
                        </div>
                        <h2 className={cl.subTitle}>Помогаем трансформировать бизнес и адаптировать его к цифровой среде</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainBlock;