import React, {useRef, useState} from 'react';
import cl from "../../scss/feedback.module.scss";
import InputMask from "react-input-mask"
import axios from "axios";
const Feedback = () => {
    const [order, setOrder] = useState({name: "", contact: ""})
    const refBtn = useRef()

    const sendOrder = async (e) =>{
        e.preventDefault()
        if(order.name!=="" && order?.contact?.replace(/[^0-9]/g,"").length === 11) {
            let form = new FormData();
            form.append("name", order.name)
            form.append("description", "Бесплатная консультация")
            form.append("contact", order.contact)
            form.append("services", "")
            try {
                await axios.post('/sendNew.php', form);
                refBtn.current.innerText = "Успех!"
                setOrder({name: "", contact: ""})
            } catch (e) {
                refBtn.current.innerText = "Ошибка"
            }
        }
    }

    return (
        <div className={cl.block}>
            <div className={cl.container}>
                 <div className={cl.row}>
                     <div className={cl.column}>
                         <div className={cl.title}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</div>
                     </div>
                     <div className={cl.column}>
                         <div className={cl.text}>Эти ваши интернеты порой бывают сложные, и если вы не знаете с чего начать, мы вас проконсультируем</div>
                     </div>
                 </div>
                <form className={cl.row}>
                    <div className={cl.column}>
                        <input value={order.name} onChange={e=> setOrder({...order, name: e.target.value})} className={cl.input} type="text" placeholder="Имя"/><br/>
                        <InputMask value={order.contact} onChange={e=> setOrder({...order, contact: e.target.value})} className={cl.input} mask="+7 999 999 99 99" maskChar=" " placeholder="Телефон" />
                    </div>
                    <div className={cl.column}>
                        <button className={cl.btn} ref={refBtn} onClick={sendOrder}>Отправить</button>
                    </div>
                </form>
                <div className={cl.confidentiality}>Нажимая кнопку отправить вы соглашаетесь с <a href="/confidentiality">политикой конфеденциальности</a></div>
            </div>
        </div>
    );
};

export default Feedback;