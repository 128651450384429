import React, {useEffect, useRef, useState} from 'react';
import cl from "../../scss/orderModal.module.scss"
import Service from "../UI/service";
import {useScrollLock} from "../../hooks";
import axios from "axios";

const Order = ({visible, setVisible}) => {
    const scroll = useScrollLock()
    const [order, setOrder] = useState({name: "", description: "", contact: "", services: []})
    const refBtn = useRef()

    useEffect(() => {
        if(visible){
            scroll.lockScroll()
        }else{
            scroll.unlockScroll()
        }
    }, [scroll, visible]);

    const close = ()=>{
        setVisible(false)
    }
    const sendOrder = async ()=>{
        if(order.name!==""&& order.contact!==""){
            let form = new FormData();
            let services = order.services.join(" ")
            form.append("name", order.name)
            form.append("description", order.description)
            form.append("contact", order.contact)
            form.append("services", services)
            try {
                await axios.post('/sendNew.php', form);
                refBtn.current.innerText = "Успех!"
                setOrder({name: "", description: "", contact: "", services: []})
            }catch (e) {
                refBtn.current.innerText = "Ошибка"
            }
        }
    }
    return (
        <div className={
            visible
            ? [cl.wrapper, cl.wrapperActive].join(" ")
            : [cl.wrapper].join(" ")
        } onClick={close}>
            <div className={cl.body} onClick={e=>e.stopPropagation()}>
                <div className={cl.close} onClick={close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                        <path d="M2 2L36 36" stroke="#8D8D8D" strokeWidth="3" strokeLinecap="round"/>
                        <path d="M36 2L2 36" stroke="#8D8D8D" strokeWidth="3" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className={cl.title}>Хочу заказать</div>
                <div className={cl.text}>Для начала работы давайте пообщаемся. Расскажите о своем проекте, укажите контакты, и мы свяжемся с вами, чтобы все обсудить.</div>
                <div className={cl.subTitle}>Выберите услуги <span>(необязательно)</span></div>
                <div className={cl.row}>
                    <Service list={order} setList={setOrder} classProperty={cl}>Сайт</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>3д моделирование</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>Дизайн сайта</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>Игровая графика</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>Интерфейс</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>Иллюстрация</Service>
                    <Service list={order} setList={setOrder} classProperty={cl}>Анимация</Service>
                </div>
                <input value={order.name} onChange={e=> setOrder({...order, name: e.target.value})} type="text" className={cl.input} placeholder="Ваше имя"/><br/>
                <input value={order.description} onChange={e=> setOrder({...order, description: e.target.value})} type="text" className={cl.input} placeholder="Расскажите о вашем проекте (необязательно)"/><br/>
                <input value={order.contact} onChange={e=> setOrder({...order, contact: e.target.value})} className={cl.input} placeholder="Телефон или любой удобный мессенджер"/><br/>
                <button className={cl.btn} onClick={sendOrder} ref={refBtn}>Отправить</button>
            </div>
        </div>
    );

};

export default Order;