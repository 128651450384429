import React from 'react';
import Audio from "../Audio";
import cl from "../../scss/about.module.scss"
const About = () => {
    return (
        <section className={cl.block}>
            <div className={cl.container}>
                <div className={cl.column}>
                    <div className={cl.gif}></div>
                </div>
                <div className={cl.column}>
                    <div className={cl.col}>
                        <div className={cl.row}>
                            <img className={cl.avatarArtur} src="/images/avatars/arturMini.svg" alt=""/>
                            <Audio/>
                        </div>
                    </div>
                    <div className={cl.col}>
                        <h2 className={cl.about}>О WEBIRAI</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;